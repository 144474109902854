import React from "react";
import { ProductsCard, ProductPrice, PricingStaticForm } from "src/components/pageComponents";
import "src/scss/_newPricing.scss";
import "src/styles/newPricing.css";
import getOGMarkup from "src/components/helpers/getOGMarkup";
import { SEO } from "src/components/commonComponents";
const Pricing = () => {

    const OGMarkup = getOGMarkup("Explore Accruent Software Pricing", 'product-pricing');

    return(
        <>
            <SEO
                title="Accruent Product Pricing"
                pageUrl="/test-product-pricing"
                OGMarkup={OGMarkup}
                meta={[{
                    name: "title",
                    content: "Explore Accruent Software Pricing | Accruent",
                  },
                  {
                    name: "description",
                    content: "Learn more about pricing for Accurent's software solutions, which are designed to give you advanced functionality at a reasonable price.",
                  },
                  { 'name': 'robots', 'content': 'noindex' }
                ]}
                alternatives={[
                    {
                        href: "/product-pricing",
                        hreflang: "en"
                    },
                    {
                        href: "/product-pricing",
                        hreflang: "x-default"
                    }
                ]}
            />
            <div className="container new-pricing_wrapper tw-mt-0 md:tw-mt-14">
                <div className="tw-py-6 tw-px-4">
                    <ProductsCard />
                </div>
                <div className="tw-py-6 tw-px-4">
                    <ProductPrice version="v2" />
                </div>
                <div className="tw-py-6 tw-px-4">
                    <ProductPrice />
                </div>
                <div className="tw-py-6 tw-px-4">
                    <ProductPrice version="v3" />
                </div>
                <div className="tw-py-6 tw-px-4">
                    <PricingStaticForm />
                </div>

            </div>
        </>
    )
}
export default Pricing;